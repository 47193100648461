import { useSearchParams } from 'react-router-dom';
import { isAndroid, isIOS } from 'react-device-detect';
import app_logo from '../app_logo.png';
import available_app_store from "../assets/available_app_store.png"
import available_google_play from "../assets/available_google_play.png"


export const Home = () => {
  const playLink = "https://play.google.com/store/apps/details?id=com.slainte.pints";
  const appleLink = "https://apps.apple.com/app/slainte/id6736952605";

  const [searchParams] = useSearchParams();
  const arrival = searchParams.get("arrival");

  if (arrival === "qr") {
    if (isAndroid) {
      window.location = playLink;
    } else if (isIOS || true) {
      window.location = appleLink;
    }
  }

  function onClick(link) {
    window.location = link;
  }

  return (
    <div className="App">
      <img src={app_logo} className="App-logo" alt="logo" />
      <h1>
        Slainte
      </h1>
      <p style={{ textAlign: "center" }}>
        The in your pocket guide to the best creamy pints in your city,
        and around the world. Review pints when out and see
        reviews left by others.
      </p>
      <div className='Download-Links'>
        <img src={available_app_store} alt="available-app-store" className='Download-Button' onClick={() => onClick(appleLink)} />
        <img src={available_google_play} alt="available-app-store" className='Download-Button' onClick={() => onClick(playLink)} />
      </div>
    </div>
  );
}